import Navbar from '../micro-site/Components/Navbar';
import { useTournamentDetails } from '../micro-site/queries/hooks';
import { useParams } from 'react-router-dom';
import SFALogo from 'assets/img/logo/sfaLogo.svg';

import { useState } from 'react';
import AsyncSearchBar from './components/AsyncSearchBar';
import { useReconciliationSearch, useSportEventByParticipantId } from './queries/hooks';
import Loader from 'components/Loader';
import Table from './components/Table';
import Uttarakhanda from 'assets/img/logo/uttarakhanda.png';
import UserTab from './components/UserTab';
import { useDebounce } from 'utils/HooksUtils';

export default function Reconciliation() {
  const params = useParams();
  const tournamentDetailsQuery = useTournamentDetails({
    tournament_id: params.tournamentId,
  });

  const [searchTab, setSearchTab] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState('');

  // object
  const [TournamentParticipant, setTournamentParticipant] = useState(null);

  return (
    <>
      <Navbar />

      <div className="bg-white py-8 my-4 md:mx-10 mb-36">
        {tournamentDetailsQuery.isLoading ? (
          <Loader />
        ) : (
          <div className="flex md:flex-col px-4 py-4 gap-3 w-full justify-center items-center md:items-between">
            {params.tournamentId === '221' ? (
              <div className="border bg-blue-950 border-gray-300 rounded-full p-1">
                <img src={Uttarakhanda} width="62px" alt="logo" />
              </div>
            ) : (
              <div className="w-18">
                <img
                  src={tournamentDetailsQuery?.data?.banner || SFALogo}
                  width="72px"
                  alt="logo"
                />
              </div>
            )}
            <div className="uppercase text-center md:text-xl font-bold text-gray-750 w-3/5 md:w-auto">
              {tournamentDetailsQuery?.data?.name}
            </div>
          </div>
        )}
        <div className="h-px bg-gray-200 mb-5" />

        <div
          style={{
            minHeight: '200px',
          }}
          className="px-4 md:px-6"
        >
          <h2 className="text-lg md:text-xl font-bold text-gray-750">Check your SFA Entries</h2>

          <SearchTabComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            tournamentId={params.tournamentId}
            TournamentParticipant={TournamentParticipant}
            setTournamentParticipant={setTournamentParticipant}
            searchTab={searchTab}
            setSearchTab={setSearchTab}
          />
          {!searchTab && <DetailTabComponent TournamentParticipant={TournamentParticipant} />}
        </div>
        <div className="px-4 md:px-6 mt-6 text-gray-650 text-sm">
          <p>
            In case of any changes or queries please contact us on{' '}
            <a href="mailto:uk.support@sfaplay.com" rel="noreferrer" target="_blank">
              <u>uk.support@sfaplay.com</u>
            </a>
          </p>
          <p>Kindly share all changes on mail BEFORE 29th April, 6 PM.</p>
        </div>
      </div>
    </>
  );
}

const SearchTabComponent = ({
  searchKeyword,
  setSearchKeyword,
  tournamentId,
  setTournamentParticipant,
  setSearchTab,
  searchTab,
}) => {
  const DeboundedSearchTerm = useDebounce(searchKeyword, 500);
  const ReconciliationSearchQueryData = useReconciliationSearch({
    tournament_id: tournamentId,
    search_word: DeboundedSearchTerm,
  });
  return (
    <>
      <AsyncSearchBar
        list={ReconciliationSearchQueryData?.data}
        searchTab={searchTab}
        setTournamentParticipant={setTournamentParticipant}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        setSearchTab={setSearchTab}
      />
    </>
  );
};

const DetailTabComponent = ({ TournamentParticipant }) => {
  if (!TournamentParticipant) return null;
  const SportEventListQueryData = useSportEventByParticipantId({
    tournament_participant: TournamentParticipant.tournament_participant,
  });
  const columns = [
    {
      key: 'index',
      component: 'IndexColumn',
      label: '',
    },
    {
      key: 'sport',
      label: 'Sport',
      component: 'TextColumn',
    },
    {
      key: 'sub_category',
      label: 'Sub Category',
      component: 'TextColumn',
    },
    {
      key: 'age_group',
      label: 'Age',
      component: 'TextColumn',
    },
    {
      key: 'gender',
      label: 'Gender',
      component: 'TextColumn',
    },
  ];

  return (
    <>
      <UserTab TournamentParticipant={TournamentParticipant} />
      {/* Table part */}
      {SportEventListQueryData.isLoading && <Loader />}
      {SportEventListQueryData?.data?.length > 0 ? (
        <div className="mt-2.5">
          <Table columns={columns} rows={SportEventListQueryData?.data} />
        </div>
      ) : (
        <div className="text-center text-gray-500 text-sm">No Participitation Till Now.</div>
      )}
    </>
  );
};
