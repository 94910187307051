const MODULE_NAME = 'micro-sites';

function generateKeyName(value) {
  return [MODULE_NAME, value];
}

const QUERY_KEYS = {
  GET_RECONCILIATION_SEARCH : generateKeyName('get-reconciliation-search'),
  GET_SPORT_EVENTS_BY_PARTICIPANT_ID : generateKeyName('get-sport-events-by-participant-id'),
};

export default QUERY_KEYS;
