import Http from 'utils/Http';

const apiEndPoints = {
getReconciliationSearch : 'sfa_online.v1.reconciliation.search',
getSportEventsByParticipantId : 'sfa_online.v1.reconciliation.get_sport_events'
};


export async function getReconciliationSearch(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getReconciliationSearch}?tournament_id=${params.tournament_id}&search_word=${params.search_word}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}

export async function getSportEventsByParticipantId(params) {
  try {
    const res = await Http.fetch({
      apiEndpoint: `${apiEndPoints.getSportEventsByParticipantId}?tournament_participant=${params.tournament_participant}`,
    });
    return res.message;
  } catch (error) {
    console.log(error);
  }
}


