import search from '../../assets/videos/Search.mp4';
import gold from '../../assets/img/gold.svg';
import silver from '../../assets/img/silver.svg';
import bronze from '../../assets/img/bronze.svg';
import { getDateInDDMMYYYY, getDateAndTime } from '../../constants/DateFunctions';
import contentCopy from '../../assets/img/contentCopy.svg';
// import ActionDots from '../../assets/img/Action-Dots.svg';
import { Transition } from '@headlessui/react';
import { useState, Fragment } from 'react';
// import Whatsapp from '../../assets/img/Whatsapp.svg';
import Edit from '../../assets/img/Edit.svg';
// import Remove from '../../assets/img/Remove.svg';
import Delete from '../../assets/img/Delete.svg';
// import { useHistory, useLocation } from 'react-router-dom';
import UnVerified from '../../assets/img/UnVerified.svg';
import Verified from '../../assets/img/Verified.svg';

function TextColumn({ row, columnKey, className }) {
  if (row[columnKey] === '' || row[columnKey] === null || row[columnKey] === undefined) {
    return <td className="pl-4 py-2.5 text-xs text-gray-750">-</td>;
  }

  return (
    <td className={'pl-4 py-2.5 text-sm text-gray-750 whitespace-nowrap ' + className}>
      {row[columnKey]}
    </td>
  );
}
function TextAndImage({ row, columnKey }) {
  if (row[columnKey]?.text)
    return (
      <td className="pl-4 py-2.5 text-sm text-gray-750">
        <img
          src={row[columnKey]?.image}
          alt=""
          className="w-8 h-8 rounded-full inline-block mr-3"
        />
        <span>{row[columnKey]?.text}</span>
      </td>
    );
  else return <td className="pl-14 py-2.5 text-xs text-gray-750">-</td>;
}

function ResultButton({ row, columnKey }) {
  if (row[columnKey]?.status === '') {
    return <td className="pl-4 py-2.5 text-xs text-gray-750">-</td>;
  }
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750">
      {row[columnKey]?.status !== 'Bronze' &&
      row[columnKey]?.status !== 'Silver' &&
      row[columnKey]?.status !== 'Gold' ? (
        <button
          className={`${
            row[columnKey]?.status === 'Qualified'
              ? 'bg-green-450 text-white'
              : 'bg-gray-300 text-gray-500'
          }  rounded-md w-28 px-4 py-1`}
        >
          {row[columnKey]?.status}
        </button>
      ) : (
        <button className="flex gap-2">
          <img
            src={
              row[columnKey].status === 'Gold'
                ? gold
                : row[columnKey].status === 'Silver'
                ? silver
                : bronze
            }
            width="25px"
            height="25px"
            alt="Medal"
          />
          <p>{row[columnKey]?.status}</p>
        </button>
      )}
    </td>
  );
}

function VideoColumn({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750">
      <div className="text-sm text-gray-900 h-8 w-34 overflow-hidden rounded-md cursor-pointer">
        <video>
          <source src={row[columnKey] ? row[columnKey] : search} />
        </video>
      </div>
    </td>
  );
}
function OpponentColumn({ row, columnKey }) {
  if (row[columnKey]?.name)
    return (
      <td className={`pl-4 py-2.5 text-gray-750 `}>
        <div className="flex flex-col">
          <span className="font-bold">{row[columnKey]?.name}</span>
          <span className="text-xs text-gray-600">{row[columnKey]?.institution}</span>
        </div>
      </td>
    );
  else return <td className="pl-14 py-2.5 text-xs text-gray-750">-</td>;
}
function DateTimeColumn({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750 flex flex-col">
      <div className="text-sm text-gray-900">
        {row[columnKey] ? getDateAndTime(row[columnKey]) : '-'}
      </div>
    </td>
  );
}

function DateColumn({ row, columnKey }) {
  return <td className="pl-4 py-2.5 text-xs text-gray-750">{getDateInDDMMYYYY(row[columnKey])}</td>;
}

function ResultMedalButton({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750">
      {row[columnKey]?.medal === 'gold' ? (
        <>
          <img src={gold} alt="gold" className="inline-block w-5 h-5 mr-2" />
          <span>Gold (1st)</span>
        </>
      ) : (
        ''
      )}
      {row[columnKey]?.medal === 'silver' ? (
        <>
          <img src={silver} alt="silver" className="inline-block w-5 h-5 mr-2" />
          <span>Silver (2nd)</span>
        </>
      ) : (
        ''
      )}
      {row[columnKey]?.medal === 'bronze' ? (
        <>
          <img src={bronze} alt="bronze" className="inline-block w-5 h-5 mr-2" />
          <span>Bronze (3rd)</span>
        </>
      ) : (
        ''
      )}
      {row[columnKey]?.medal === '' || row[columnKey]?.medal === 'participated' ? (
        <>
          <span>Participated</span>
        </>
      ) : (
        ''
      )}
    </td>
  );
}

function DeleteColumm({ row, _columnKey, deleteParticipantHandler }) {
  return (
    <td style={{ width: '50px' }} className="">
      <img
        title="Remove Player"
        src={Delete}
        width="25px"
        height="25px"
        className="cursor-pointer block"
        onClick={() => deleteParticipantHandler(row?.sport_event_participant)}
        alt={'Remove Player'}
      />
    </td>
  );
}

function StatusColumn({ row, columnKey }) {
  return (
    <td className="px-6 py-2.5 whitespace-nowrap">
      <div
        className={`text-sm py-0.5 px-1.5 text-center rounded 
      
      ${row[columnKey].color === 'red' ? 'bg-red-150 text-red-350' : null}
    ${row[columnKey].color === 'green' ? 'bg-green-150 text-green-450' : null}
     ${row[columnKey].color === 'gray' ? 'bg-gray-150 text-gray-500' : null}
    
    `}
      >
        {row[columnKey].status}
      </div>
    </td>
  );
}

function LinkColumn({
  row,
  columnKey,
  tournamentId,
  subCategory,
  variant,
  sport,
  gender,
  ageGroup,
}) {
  const [Notcopied, setCopied] = useState(true);
  const link = `${row[columnKey]}`;
  return (
    <td
      className={`px-2 lg:px-4 py-2.5 block-flex relative ${
        variant === 'reverse' ? 'text-right' : ''
      }`}
    >
      <Transition
        show={!Notcopied}
        // show={showTooltip}
        as={Fragment}
        enter="transition ease-out duration-500"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-500"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div
          className={`mt-2 absolute w-20 px-4 py-1 top-2 right-1/2 rounded-md text-white font-xxs bg-gray-750`}
        >
          Copied!
        </div>
      </Transition>

      <span
        className="overflow-hidden inline-flex text-ellipsis justify-start"
        style={{ width: '90%' }}
      >
        {link}
      </span>
      <img
        src={contentCopy}
        alt="copy"
        className={`absolute ${
          variant === 'reverse' ? 'left-0' : 'right-0'
        } ml-2 lg:ml-4 -mt-0.5 inline-block cursor-pointer`}
        onClick={(e) => {
          e.stopPropagation();
          e.target.style.transform = 'scale(0.8)';
          setCopied(false);
          navigator.clipboard.writeText(
            `CONGRATULATIONS! You have been selected to represent our school for (${
              sport +
              ' ' +
              (subCategory !== null ? subCategory + ' ' : '') +
              gender +
              ' ' +
              ageGroup +
              row.team_name.replace(sport, '')
            }) at please click the link to register - ${link}`
          );

          setTimeout(() => {
            e.target.style.transform = 'scale(1)';
            setCopied(true);
          }, 1000);
        }}
      />
    </td>
  );
}

function OptionsColumn({
  row,
  _columnKey,
  _deleteTeamHandler,
  setUnderViewTeam,
  setTeaminManage,
  _Modal,
  // IsDeleteModalOpen,
  setIsDeleteModalOpen,
  setTeamToDelete,
}) {
  return (
    <td className="w-20 ml-4 py-5 flex justify-between items-center">
      <img
        title="Manage Team"
        src={Edit}
        alt=""
        className="cursor-pointer rounded-full hover:bg-gray-100 mr-2 inline-block"
        onClick={(e) => {
          e.stopPropagation();
          setUnderViewTeam(false);
          setTeaminManage(row?.team_id);
        }}
      />
      {row.accepted_by > 0 ? null : (
        <img
          src={Delete}
          title="Delete Team"
          alt=""
          className="cursor-pointer rounded-full hover:bg-gray-100 mr-2 inline-block"
          onClick={(e) => {
            e.stopPropagation();
            setTeamToDelete(row?.team_id);
            setIsDeleteModalOpen(true);
          }}
        />
      )}
    </td>
  );
}
function ScoreColumn({ row, columnKey }) {
  if (row[columnKey]) {
    return (
      <td className="w-14 md:px-3">
        <span
          className="text-white px-1.5 py-0.5 rounded-md"
          style={{
            backgroundColor: 'rgba(93, 95, 239, 1)',
          }}
        >
          {row[columnKey]}
        </span>
      </td>
    );
  } else return <td className="pl-14 py-2.5 text-xs text-gray-750">-</td>;
}

function RoundColumn({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-gray-750 whitespace-nowrap">
      <div className="text-sm">{row[columnKey].split('-')[0]}</div>
      <p className="text-xxs text-gray-450">{row?.format}</p>
    </td>
  );
}

function VerifiedStatusColumn({ row, columnKey }) {
  if (row[columnKey].status === 'Reject' || row[columnKey].status === 'Pending') {
    return (
      <td className="px-2 py-2.5 flex justify-center" title={row[columnKey].status}>
        <img src={UnVerified} alt="verified" width="24px" height="24px" className="inline-block" />
      </td>
    );
  } else if (row[columnKey].status === 'Verified') {
    return (
      <td className="px-2 py-2.5 flex justify-center">
        <img
          src={Verified}
          alt="not verified"
          width="24px"
          height="24px"
          className="inline-block"
        />
      </td>
    );
  }
}

export default function Table({
  Modal,
  setUnderViewTeam,
  setTeaminManage,
  columns,
  rows,
  rowClickable,
  rowClickableFunction,
  _data,
  fromTeamCreationCoach,
  deleteTeamHandler,
  deleteParticipantHandler,
  tournamentId,
  _IsDeleteModalOpen,
  setIsDeleteModalOpen,
  setTeamToDelete,
  teamStatus,
  sport,
  gender,
  ageGroup,
  teamName,
  subCategory,
}) {
  return (
    <div
      className={`overflow-x-auto w-full  mt-4 md:mt-0 max-w-full border-collapse border border-gray-200 bg-white ${
        !fromTeamCreationCoach ? 'pb-3 rounded-lg' : 'rounded-t-lg'
      }`}
    >
      <table className="divide-y divide-gray-200 tb text-left text-base w-full font-roboto whitespace-nowrap">
        <thead className="">
          <tr className="text-left border-b border-gray-300">
            {columns.map((column) => (
              <TableHeaderRow key={column.key} text={column.label} />
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => {
            return (
              <tr
                key={index}
                className={`text-sm ${rowClickable ? 'hover:bg-gray-100 cursor-pointer' : ''}`}
                onClick={() => {
                  if (rowClickable) rowClickableFunction(row?.team_id);
                }}
              >
                {columns.map((column, _key) => {
                  // console.log(column, key);
                  // console.log(index,key);
                  return (
                    <TableRenderRow
                      key={column.key}
                      rowIndex={index}
                      column={column}
                      row={row}
                      deleteTeamHandler={deleteTeamHandler}
                      deleteParticipantHandler={deleteParticipantHandler}
                      setUnderViewTeam={setUnderViewTeam}
                      setTeaminManage={setTeaminManage}
                      tournamentId={tournamentId}
                      Modal={Modal}
                      teamStatus={teamStatus}
                      // IsDeleteModalOpen={IsDeleteModalOpen}
                      setIsDeleteModalOpen={setIsDeleteModalOpen}
                      setTeamToDelete={setTeamToDelete}
                      sport={sport}
                      gender={gender}
                      ageGroup={ageGroup}
                      teamName={teamName}
                      subCategory={subCategory}
                    />
                  );
                })}{' '}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function IndexColumn({ _row, _columnKey, rowIndex }) {
  return <td className="pl-4 py-2.5 text-sm text-gray-750 whitespace-nowrap">{rowIndex}</td>;
}

function TeamColumn({ row, _columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-sm text-gray-750 whitespace-nowrap">
      <div className="flex flex-col gap-px">
        <p className="text-black">{row?.team_name}</p>
      </div>
    </td>
  );
}

function TableHeaderRow({ text }) {
  return (
    <>
      <th
        className={`block-flex items-center px-4 py-2.5 ${
          text === 'Verification Status' ? 'text-center' : ''
        }`}
      >
        <span className="whitespace-nowrap text-gray-850 text-sm font-medium ">{text}</span>
      </th>
    </>
  );
}

function TableRenderRow({
  row,
  column,
  rowIndex,
  deleteTeamHandler,
  deleteParticipantHandler,
  setUnderViewTeam,
  setTeaminManage,
  tournamentId,
  Modal,
  teamStatus,
  // IsDeleteModalOpen,
  setIsDeleteModalOpen,
  setTeamToDelete,
  sport,
  gender,
  ageGroup,
  teamName,
  subCategory,
}) {
  if (column.component === 'TextColumn')
    return <TextColumn row={row} columnKey={column.key} className={column.className} />;

  if (column.component === 'TextAndImage') return <TextAndImage row={row} columnKey={column.key} />;

  if (column.component === 'ResultButton') return <ResultButton row={row} columnKey={column.key} />;

  if (column.component === 'VideoColumn') return <VideoColumn row={row} columnKey={column.key} />;

  if (column.component === 'OpponentColumn')
    return <OpponentColumn row={row} columnKey={column.key} />;

  if (column.component === 'DateTimeColumn')
    return <DateTimeColumn row={row} columnKey={column.key} />;

  if (column.component === 'ResultMedalButton')
    return <ResultMedalButton row={row} columnKey={column.key} />;

  if (column.component === 'DeleteColumm')
    return (
      <DeleteColumm
        row={row}
        columnKey={column.key}
        deleteParticipantHandler={deleteParticipantHandler}
      />
    );

  if (column.component === 'StatusColumn') return <StatusColumn row={row} columnKey={column.key} />;

  if (column.component === 'LinkColumn')
    return (
      <LinkColumn
        row={row}
        columnKey={column.key}
        tournamentId={tournamentId}
        variant={column.variant}
        teamName={teamName}
        sport={sport}
        gender={gender}
        ageGroup={ageGroup}
        subCategory={subCategory}
      />
    );

  if (column.component === 'OptionsColumn')
    return (
      <OptionsColumn
        row={row}
        columnKey={column.key}
        deleteTeamHandler={deleteTeamHandler}
        setUnderViewTeam={setUnderViewTeam}
        setTeaminManage={setTeaminManage}
        Modal={Modal}
        // IsDeleteModalOpen={IsDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        setTeamToDelete={setTeamToDelete}
      />
    );

  if (column.component === 'VerifiedStatusColumn')
    return <VerifiedStatusColumn row={row} columnKey={column.key} />;

  if (column.component === 'DateColumn') return <DateColumn row={row} columnKey={column.key} />;
  if (column.component === 'IndexColumn')
    return <IndexColumn row={row} columnKey={column.key} rowIndex={rowIndex + 1} />;

  if (column.component === 'TeamColumn') return <TeamColumn row={row} columnKey={column.key} />;
  if (column.component === 'ScoreColumn') return <ScoreColumn row={row} columnKey={column.key} />;
  if (column.component === 'RoundColumn') return <RoundColumn row={row} columnKey={column.key} />;

  else return <td className="pl-4 py-2.5 text-xs text-gray-750 text-center">-</td>;

}
