import React, { useRef } from "react";

const AsyncSearchBar = ({ searchKeyword, setSearchKeyword, list, setTournamentParticipant, searchTab, setSearchTab }) => {

  const InputSearchRef = useRef(null);

  return (
    <div className="mt-6">
      <div className="flex flex-col gap-4 md:flex-row justify-start items-end md:items-center w-full">
        <div className="relative w-full md:w-80">

          <label className=" flex gap-3 h-11 w-full md:w-80 border px-4 rounded-lg">
            <div className="py-3">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline -mt-0.5" fill="none" viewBox="0 0 24 24" stroke="grey" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <input placeholder="Search by Name or SFA-ID" ref={InputSearchRef} value={searchKeyword} onChange={e => { setSearchKeyword(e.target.value) }} className="text-xs md:text-sm outline-none w-full" />
          </label>
          {searchKeyword && list && list.length > 0 ? <div className="absolute top-12 w-full md:w-80 bg-white border p-2 rounded shadow z-10">
            {/* top 5 results */}
            {searchKeyword && list && list.splice(0, 5).map((_, i) => <ItemComponent key={i} item={_} searchKeyword={searchKeyword} setSearchTab={setSearchTab} setSearchKeyword={setSearchKeyword} setTournamentParticipant={setTournamentParticipant} />)}
          </div>
            : null
          }
        </div>
        {!searchTab && <button
          onClick={() => {
            setSearchTab(true)
            setSearchKeyword('')
            setTournamentParticipant(null)
            InputSearchRef.current.focus()
          }
          }
          className='text-xs md:text-sm font-semibold'
        >Clear</button>}
      </div>
    </div>
  );
};

const ItemComponent = ({ item, setTournamentParticipant, setSearchTab, setSearchKeyword, searchKeyword }) => {
  return (<button
    onClick={() => {
      setTournamentParticipant(item)
      setSearchTab(false)
      setSearchKeyword(item?.athlete_name)

    }}
    className="bg-white py-2.5 px-1 md:px-2 hover:bg-gray-100 cursor-pointer flex justify-between text-xs md:text-sm w-full">
    {/* <span>{item?.athlete_name}</span> */}

    <span
      className='text-gray-400'
      dangerouslySetInnerHTML={
        { __html: item?.athlete_name?.replace(new RegExp(searchKeyword, 'gi'), `<span class="text-black">${searchKeyword.toUpperCase()}</span>`) }
      }
    />

    {/* <span>{item?.sfa_id}</span> */}
    <span
      className='text-gray-400'
      dangerouslySetInnerHTML={
        { __html: item?.sfa_id?.replace(new RegExp(searchKeyword, 'gi'), `<span class="text-black">${searchKeyword.toUpperCase()}</span>`) }
      }
    />


  </button>)
}


export default AsyncSearchBar;