import { useState,Fragment } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { Transition } from '@headlessui/react';
import UnVerified from 'assets/img/VerificationStatus/Unverified.svg'
import Rejected from 'assets/img/VerificationStatus/Rejected.svg'
import Verified from 'assets/img/VerificationStatus/Verified.svg'

export default function UserTab({TournamentParticipant}) {
    const [showTooltip, setShowTooltip] = useState(false);

    return(<>
            {/*     Web View 
                <div
            style={{
                backgroundColor: "rgba(39, 39, 39, 0.9)"
            }}
            className='my-6 p-2 md:p-3 rounded-lg hidden md:block'>
            <div className='flex gap-2.5 items-center'>
                <div className='bg-cover bg-center bg-no-repeat aspect-square rounded-full'
                    style={{
                        backgroundImage: `url(${TournamentParticipant?.image})`,
                        height: "32px",
                        width: "32px"
                    }}
                ></div>

                <div className='flex flex-col'>
                    <div className='flex gap-1.5 items-center'>
                        <span className='text-sm text-white'>{TournamentParticipant?.athlete_name}</span>
                        <img title={TournamentParticipant?.status} src={TournamentParticipant?.status === "Unverified" ? UnVerifiedRed : Verified} alt={TournamentParticipant?.status === "Unverified" ? "unverifed" : 'verified'} className='w-4 h-4' />
                        <button
                            className="flex gap-3 px-2 py-1 items-center bg-blue-100 rounded"
                            onClick={(e) => {
                                navigator.clipboard.writeText(TournamentParticipant?.sfa_id);
                                setShowTooltip(true);
                                e.target.classList.add('animate-button');
                                setTimeout(() => {
                                    e.target.classList.remove('animate-button');
                                    setShowTooltip(false);
                                }, 3000);
                            }}
                        >
                            <p data-cy="sfa_id" className="text-blue-650 text-sm">
                                {TournamentParticipant?.sfa_id}
                            </p>
                            <img
        src={contentCopy}
        alt="copy"
        className="-mt-0.5 inline-block cursor-pointer h-4"/>
                        </button>
                        <Transition
                            show={showTooltip}
                            as={Fragment}
                            enter="transition ease-out duration-500"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-500"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <div className={`px-4 py-1 rounded-md text-white font-xs bg-gray-750`}>
                                Copied!
                            </div>
                        </Transition>

                    </div>
                    <div className='text-gray-200 opacity-60 text-xxs md:text-sm'>{TournamentParticipant?.institution}</div>
                </div>
            </div>
        </div>
        Web View */}

        {/* Mobile View */}
        <div
            className='my-6 p-2 md:p-3 rounded-lg border'>
            <div className='flex gap-2.5 items-center'>
                <div className='bg-cover bg-center bg-no-repeat aspect-square rounded-full'
                    style={{
                        backgroundImage: `url(${TournamentParticipant?.image})`,
                        height: "32px",
                        width: "32px"
                    }}
                ></div>
                {/* <img src={TournamentParticipant?.image} width="32px" height="32px" className='aspect-square rounded-full' alt='dp' /> */}
                <div className='flex flex-col md:gap-1'>
                    <div className='flex gap-1.5 items-center'>
                        <span className='text-sm'>{TournamentParticipant?.athlete_name}</span>
                        <img title={TournamentParticipant?.status} src={TournamentParticipant?.status === "Unverified" ? UnVerified : (TournamentParticipant?.status === "Verified"? Verified: Rejected)} alt={TournamentParticipant?.status === "Unverified" ? "UnVerified" : (TournamentParticipant?.status === "Verified"? "Verified": "Rejected")} className='w-4 h-4' />
                        <button
                            className="flex gap-3 px-2 py-1 items-center bg-blue-50 rounded"
                            onClick={(e) => {
                                navigator.clipboard.writeText(TournamentParticipant?.sfa_id);
                                setShowTooltip(true);
                                e.target.classList.add('animate-button');
                                setTimeout(() => {
                                    e.target.classList.remove('animate-button');
                                    setShowTooltip(false);
                                }, 3000);
                            }}
                        >
                            <p data-cy="sfa_id" className="text-blue-650 text-sm">
                                {TournamentParticipant?.sfa_id}
                            </p>
                            <FontAwesomeIcon icon={faCopy} className="text-blue-650 text-sm" />
                        </button>
                        <Transition
                            show={showTooltip}
                            as={Fragment}
                            enter="transition ease-out duration-500"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-500"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <div className={`mt-2 px-4 py-1 rounded-md text-white font-xxs bg-gray-750`}>
                                Copied!
                            </div>
                        </Transition>

                        {/* <span className='bg-gray-450 text-gray-200 p-1 text-xxs md:text-xs rounded whitespace-nowrap'>{TournamentParticipant?.sfa_id}</span> */}
                    </div>
                    <div className='gray-200 opacity-60 text-xs md:text-sm'>{TournamentParticipant?.institution}</div>
                </div>
            </div>
        </div>
        {/* Mobile View */}


    </>)
}