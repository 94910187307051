import QUERY_KEYS from './queryKeys';
import { useQuery } from 'react-query';
import { getReconciliationSearch,getSportEventsByParticipantId } from 'Reconciliation/services';

export const useReconciliationSearch = (params) => {
    return useQuery(
      [
        ...QUERY_KEYS.GET_RECONCILIATION_SEARCH,
        params?.tournament_id ? params?.tournament_id : '',
        params?.search_word ? params?.search_word : '',
      ],
  
      () => getReconciliationSearch(params),
      {
        retry: false,
      }
    );
  };
  
  export const useSportEventByParticipantId = (params) => {
    return useQuery(
      [
        ...QUERY_KEYS.GET_SPORT_EVENTS_BY_PARTICIPANT_ID,
        params?.tournament_participant ? params?.tournament_participant : '',
      ],
  
      () => getSportEventsByParticipantId(params),
      {
        retry: false,
      }
    );
  };
  
